/**
 * Middleware to initialize the user data if the user is logged in or
 * if the route is accessed with login from external recourse(external login/registration forms etc.)
 *
 * The middleware performs the following steps:
 * 1. Checks if the code is running on the client-side.
 * 2. Determines if the login from external recourse was done(check loggedFromExternalResource query).
 * 3. If the user is logged in and not navigating from the login page or if the
 *    external login was done it calls the initializeUser action.
 * 4. If the loggedFromExternalResource query was present it removes this parameter
 *    from the URL using.
 *
 * @param {Route} to - The target route object the user is navigating to.
 * @param {Route} from - The current route object the user is navigating from.
 */
import { useAuthStore } from '~/stores/auth';
import { useCashStore } from '~/stores/cash';
import { CASH_TYPES } from '~/constants/cash';
import { REDIRECTION_ROUTES_QUERIES } from '~/constants/redirectionRoutes';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    getUserData,
    initializeUser,
  } = useAuthStore();
  const { activateCash } = useCashStore();
  const cashierType = to.query[REDIRECTION_ROUTES_QUERIES.cashier] as string;

  if (import.meta.client) {
    const isLoggedFromExternalResource = to.query?.loggedFromExternalResource === 'true';

    if ((getUserData.loggedIn && !from.path.includes('/login')) || isLoggedFromExternalResource) {
      await initializeUser();
      if (CASH_TYPES.includes(cashierType)) {
        activateCash();
      }
      if (isLoggedFromExternalResource) {
        const clearedQuery = { ...to.query };

        delete clearedQuery.loggedFromExternalResource;
        await useRouter().replace({ query: clearedQuery });
      }
    }
  }
});
